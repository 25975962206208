<template lang="pug">
v-container(fill-height,fluid,grid-list-xl)
  v-layout(justify-center,wrap)
    v-flex(md12, v-if="loaded")
      v-alert(:value="true", type="error")
        strong {{ tipo }}: {{ erro }}

    v-flex(md12)
      data-table(
        :fields="fields"
        :dados="dados"
        :loaded="loaded"
        :order="order"
      )
</template>

<script>

import { mapMutations } from 'vuex';
import { erros as api } from '@/api';

export default {
  data: () => ({
    erro: '',
    loaded: false,
    dados: [],
    fields: api.fieldsDetalhes,
    order: api.order,
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
  },
  async mounted() {
    this.isControl(true);
    this.setTitle('Erros');

    api.refreshAuthProd(this.$store.state.auth);
    try {
      this.dados = await api.getByHash(this.$route.params.hash);
      console.log(this.dados);
      this.loaded = !!this.dados;
      this.tipo = this.dados[0].tipo;
      this.erro = this.dados[0].erro;
    } catch (e) {
      this.$store.dispatch('alert/error',
        'Ocorreu algum problema ao buscar as informações',
        { root: true });
      this.loaded = false;
    }
  },
  created() {
    this.$store.dispatch('auth/verify');
  },
};
</script>
